import { ContractType } from 'src/app/enums/contract-type.enum';
import { BookOpenIconComponent } from 'src/app/ui-kit/icons/book-icon.component';
import { PencilLineIconComponent } from 'src/app/ui-kit/icons/pencil-line-icon.component';

export const BASE_MANAGEMENT_AGREEMENT = {
  type: ContractType.MANAGEMENT_AGREEMENT,
  title: $localize`Mandat de gestion`,
  // eslint-disable-next-line no-irregular-whitespace
  subtitle: $localize`Le mandat de gestion est un document qui nous permet d’agir en ton nom pour :`,
  buttonLabel: $localize`Signer le mandat de gestion`,
  signedTitle: $localize`Mandat de gestion signé`,
  signedButtonLabel: $localize`Mandat de gestion signé`,
  modalTitle: $localize`Mandat de gestion`,
  modalButtonLabel: $localize`Signer le document`,
  iconComponent: PencilLineIconComponent,
  bulletPoints: [
    $localize`Comparer et négocier les meilleures offres`,
    $localize`Centraliser tes contrats et optimiser ta couverture`,
    $localize`Simplifier tes démarches administratives. Rien ne sera modifié sans ton accord, mais ce mandat nous permet de t’offrir un accompagnement complet et sur-mesure`,
  ],
};

export const BASE_ARTICLE_45 = {
  type: ContractType.ARTICLE_45,
  title: $localize`Article 45 de la Loi sur la Surveillance des Assurances (LSA)`,
  // eslint-disable-next-line no-irregular-whitespace
  subtitle: $localize`L’article 45 de la Loi sur la Surveillance des Assurances garantit que tu disposes de toutes les informations nécessaires avant toute collaboration. Cela inclut :`,
  buttonLabel: $localize`Lire le document et approuver`,
  signedButtonLabel: $localize`Document lu et approuvé`,
  signedTitle: $localize`Tes intérêts sont protégés`,
  modalTitle: $localize`Loi sur la Surveillance des Assurances (LSA45)`,
  modalButtonLabel: $localize`Valider`,
  iconComponent: BookOpenIconComponent,
  bulletPoints: [
    $localize`Notre identité en tant que courtier indépendant`,
    $localize`Les compagnies d'assurance dont nous proposons les produits`,
    $localize`La transparence sur l’utilisation de tes données personnelles`,
  ],
};
